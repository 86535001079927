import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet tot ell de coloració que va del groc or al groc safrà més o manco rogenc. Capell de fins a 10 o 15 cm de diàmetre, primer convex però esdevé en forma d’embut on el marge es troba moltes vegades aixecat. El peu és central o excèntric, més o manco corbat i té nombroses làmines decurrents cap al peu de color groc daurat safranat que ens poden tacar les mans en tocar-les. Les espores són blanques en massa, d’ovoides a subgloboses, llises, 5-7 x 5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      